import { FacebookInfluencers, FacebookInfluencerProfile } from '@/shared/organisms';
import { InfluencersTabType } from '@/shared/types';
import { useSelectSocialAccountsForPackage } from './hooks';

export const FacebookPackageInfluencersSearch = () => {
  const { getCurrentProfiles, getSortSelectButtonProps, getListSelectButtonProps } =
    useSelectSocialAccountsForPackage<FacebookInfluencerProfile>({ socialMedia: InfluencersTabType.FACEBOOK });

  return (
    <FacebookInfluencers
      isPackagePage
      getCurrentProfiles={getCurrentProfiles}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
