import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from '@/shared/hooks';
import { useCheckInfluencersAddedToPackageQuery } from '@/graphql';
import { InfluencerProfile, usePackageProposalContext } from '@/shared/organisms';
import { useToggleSelectedPackageInfluencers } from './useToggleSelectedPackageInfluencers';

export const useAllInfluencersSelectForPackage = () => {
  const { t } = useTranslation();
  const { proposalSummary } = usePackageProposalContext();
  const { toggleSelectedInfluencer } = useToggleSelectedPackageInfluencers();
  const [currentInfluencerProfilesInPage, setCurrentInfluencerProfilesInPage] = useState<Array<InfluencerProfile>>([]);
  const { data } = useCheckInfluencersAddedToPackageQuery({
    fetchPolicy: 'no-cache',
    skip: !proposalSummary?.id || !currentInfluencerProfilesInPage.length,
    variables: {
      socialPairs: [],
      packageId: Number(proposalSummary?.id),
      influencerIds: currentInfluencerProfilesInPage.map((infl) => infl.id)
    }
  });

  const influencerIdsAddedToPackage = data?.checkInfluencersAddedToPackage?.influencerIds;

  const [togglingState, setTogglingState] = useState<{ id: number | null; loading: boolean }>({
    id: null,
    loading: false
  });

  const resetLoadingState = () => {
    setTogglingState({ id: null, loading: false });
  };

  useDeepCompareEffect(() => {
    resetLoadingState();
  }, [influencerIdsAddedToPackage]);

  const getSortSelectButtonProps = (listRecords: readonly InfluencerProfile[]) => {
    const allInfluencerIdsInPage = listRecords.map((infl) => infl.id);
    const selectedAccountsInPage = listRecords.reduce<number[]>(
      (rs, infl) => (influencerIdsAddedToPackage?.includes(infl.id) && !rs.includes(infl.id) ? [...rs, infl.id] : rs),
      []
    );
    const listRecordsLength = listRecords.length;
    const loading = !togglingState.id && togglingState.loading;
    const numOfSelectedAccountsInPage = selectedAccountsInPage.length;
    const hasAtLeastOneAccountSelectedInPage = numOfSelectedAccountsInPage > 0;
    const hasAllAccountsSelectedInPage = listRecordsLength > 0 && selectedAccountsInPage.length >= listRecordsLength;

    return {
      isInfluencerAccount: true,
      selectedAccountsInPage,
      loading,
      onClick: async () => {
        setTogglingState({ id: null, loading: true });
        const { error } = await toggleSelectedInfluencer(allInfluencerIdsInPage, hasAllAccountsSelectedInPage);
        if (error) {
          resetLoadingState();
        }
      },
      disabled: loading || listRecords.length === 0,
      checked: hasAtLeastOneAccountSelectedInPage,
      indeterminate: numOfSelectedAccountsInPage !== listRecordsLength,
      label: hasAtLeastOneAccountSelectedInPage
        ? t('numberInfluencersAddedToPackage', {
            count: numOfSelectedAccountsInPage,
            packageName: proposalSummary?.name
          })
        : t('addInfluencersToPackage', { packageName: proposalSummary?.name })
    };
  };

  const getListSelectButtonProps = ({ id }: InfluencerProfile) => {
    const isSelected = influencerIdsAddedToPackage?.includes(id) || false;
    const loading = togglingState.id === id && togglingState.loading;

    return {
      loading,
      checked: isSelected,
      disabled: loading,
      onClick: async () => {
        setTogglingState({ id, loading: true });
        const { error } = await toggleSelectedInfluencer([id], isSelected);
        if (error) {
          resetLoadingState();
        }
      }
    };
  };

  return {
    getSortSelectButtonProps,
    getListSelectButtonProps,
    getCurrentProfiles: setCurrentInfluencerProfilesInPage
  };
};
