import { YoutubeInfluencers, YoutubeInfluencerProfile } from '@/shared/organisms';
import { InfluencersTabType } from '@/shared/types';
import { useSelectSocialAccountsForPackage } from './hooks';

export const YoutubePackageInfluencersSearch = () => {
  const { getCurrentProfiles, getSortSelectButtonProps, getListSelectButtonProps } =
    useSelectSocialAccountsForPackage<YoutubeInfluencerProfile>({ socialMedia: InfluencersTabType.YOUTUBE });

  return (
    <YoutubeInfluencers
      isPackagePage
      getCurrentProfiles={getCurrentProfiles}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
