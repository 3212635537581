import { useState } from 'react';
import { getValuableVariables } from '@/shared/utils';
import { ParamsSearchReturnType } from '@/shared/hooks';
import { DouyinInfluencerSearchResult, useAllDouyinInfluencersQuery } from '@/graphql';
import { formatInfluencerProfile } from '../utils';
import { DouyinInfluencersSearchSchemaType, DouyinInfluencersProps } from '../schemaTypes';
import { useGetInfluencersQueryVariables } from '../../hooks';

type Props = Pick<ParamsSearchReturnType<DouyinInfluencersSearchSchemaType>, 'page' | 'limit' | 'filter' | 'sort'> &
  Pick<DouyinInfluencersProps, 'getCurrentProfiles'>;

export const useDouyinInfluencersList = ({ page, sort, limit, filter, getCurrentProfiles }: Props) => {
  // Using data directly from query can be empty if skipSearch is true
  // But we still want to keep old data in that case, so we store it in state
  const [data, setData] = useState<DouyinInfluencerSearchResult>();
  const { skipSearch, variables } = useGetInfluencersQueryVariables({ page, limit, filter });
  const { loading } = useAllDouyinInfluencersQuery({
    skip: skipSearch || !filter,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ allDouyinInfluencers }) => {
      setData(allDouyinInfluencers);
      getCurrentProfiles?.(allDouyinInfluencers.douyinAccounts.map(formatInfluencerProfile));
    },
    variables: getValuableVariables({ ...variables, orderBy: sort })
  });

  return {
    loading,
    totalRecords: data?.totalNumber || 0,
    listRecords: data?.douyinAccounts.map(formatInfluencerProfile) || []
  };
};
