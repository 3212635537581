import { InstagramInfluencers, InstagramInfluencerProfile } from '@/shared/organisms';
import { InfluencersTabType } from '@/shared/types';
import { useSelectSocialAccountsForPackage } from './hooks';

export const InstagramPackageInfluencersSearch = () => {
  const { getCurrentProfiles, getSortSelectButtonProps, getListSelectButtonProps } =
    useSelectSocialAccountsForPackage<InstagramInfluencerProfile>({ socialMedia: InfluencersTabType.INSTAGRAM });

  return (
    <InstagramInfluencers
      isPackagePage
      getCurrentProfiles={getCurrentProfiles}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
