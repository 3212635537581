import React, { createContext, useContext } from 'react';
import { ListWithPagination } from '@/shared/molecules';
import { SocialAccountInfluencerListSortFieldV2 } from '@/graphql';
import { useInfluencerParamsSearch } from '../hooks';
import { InfluencersContextReturnType } from '../schemaTypes';
import { useFacebookInfluencersList } from './hooks';
import {
  FacebookInfluencersProps,
  FacebookInfluencerProfile,
  FacebookInfluencersFilterFormValues,
  FacebookInfluencersSearchSchemaType
} from './schemaTypes';

type ContextType = InfluencersContextReturnType<
  FacebookInfluencerProfile,
  FacebookInfluencersFilterFormValues,
  SocialAccountInfluencerListSortFieldV2
>;

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  value: FacebookInfluencersProps;
  children: React.ReactElement | Array<React.ReactElement>;
}

export const FacebookInfluencersProvider = ({
  children,
  value: {
    isTtcmCampaign,
    isPackagePage,
    isEngagementPage,
    getCurrentProfiles,
    getSortSelectButtonProps,
    getListSelectButtonProps
  }
}: Props) => {
  const paramsSearch = useInfluencerParamsSearch<FacebookInfluencersSearchSchemaType>();
  const { page, sort, limit, filter } = paramsSearch;
  const listProps = useFacebookInfluencersList({ page, sort, limit, filter, getCurrentProfiles });

  return (
    <Context.Provider
      value={{
        ...paramsSearch,
        ...listProps,
        isPackagePage,
        isTtcmCampaign,
        isEngagementPage,
        getListSelectButtonProps,
        sortSelectButtonProps: getSortSelectButtonProps?.(listProps.listRecords, listProps.totalRecords)
      }}
    >
      <ListWithPagination pagination={{ totalRecords: listProps.totalRecords }}>{children}</ListWithPagination>
    </Context.Provider>
  );
};

export const useFacebookInfluencersContext = () => useContext<ContextType>(Context);
