import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from '@/shared/hooks';
import { SocialAccountType, useCheckInfluencersAddedToPackageQuery } from '@/graphql';
import { SelectedSocialMediaAccountsType, usePackageProposalContext } from '@/shared/organisms';
import { useToggleSelectedSocialAccountsForPackage } from './useToggleSelectedSocialAccountsForPackage';

type SocialProfile = Record<string, any> & {
  id: number | string;
  socialAccountType: SocialAccountType;
  socialAccountId?: number | string | null;
};

interface Props {
  socialMedia: keyof typeof SocialAccountType;
}

export const useSelectSocialAccountsForPackage = <Profile extends SocialProfile>({ socialMedia }: Props) => {
  const { t } = useTranslation();
  const { proposalSummary } = usePackageProposalContext();
  const [currentProfilesInPage, setCurrentProfilesInPage] = useState<Array<Profile>>([]);
  const { toggleSocialMediaSelectedInfluencer } = useToggleSelectedSocialAccountsForPackage();
  const { data } = useCheckInfluencersAddedToPackageQuery({
    skip: !proposalSummary?.id || !currentProfilesInPage.length,
    variables: {
      influencerIds: [],
      packageId: Number(proposalSummary?.id),
      socialPairs: currentProfilesInPage.map((profile) => ({
        influencerId: Number(profile.id),
        socialType: SocialAccountType[socialMedia],
        socialAccountId: Number(profile.socialAccountId)
      }))
    }
  });
  const [togglingState, setTogglingState] = useState<{ id: number | null; loading: boolean }>({
    id: null,
    loading: false
  });

  const socialPairsAddedToPackage = data?.checkInfluencersAddedToPackage?.socialPairs;

  const resetLoadingState = () => {
    setTogglingState({ id: null, loading: false });
  };

  useDeepCompareEffect(() => {
    resetLoadingState();
  }, [socialPairsAddedToPackage]);

  const getSortSelectButtonProps = (listRecords: readonly Profile[]) => {
    const { selectedIds, selectedSocialMediaAccounts } = listRecords.reduce<{
      selectedIds: number[];
      selectedSocialMediaAccounts: SelectedSocialMediaAccountsType[];
    }>(
      (rs, infl) => {
        const inflId = Number(infl.id);
        const socialAccountId = Number(infl.socialAccountId);

        if (
          !!socialPairsAddedToPackage?.length &&
          socialPairsAddedToPackage?.findIndex(
            (pair) =>
              pair.influencerId === inflId &&
              pair.socialAccountId === socialAccountId &&
              pair.socialType === infl.socialAccountType
          ) !== -1 &&
          !rs.selectedIds.includes(socialAccountId)
        ) {
          rs.selectedIds.push(socialAccountId);
          rs.selectedSocialMediaAccounts.push({
            socialAccountId: infl.socialAccountId,
            socialAccountType: infl.socialAccountType,
            influencerId: inflId
          });
        }
        return rs;
      },
      { selectedIds: [], selectedSocialMediaAccounts: [] }
    );
    const listRecordsLength = listRecords.length;
    const loading = !togglingState.id && togglingState.loading;
    const numOfSelectedAccountsInPage = selectedIds.length;
    const hasAtLeastOneAccountSelectedInPage = numOfSelectedAccountsInPage > 0;
    const hasAllAccountsSelectedInPage = listRecordsLength > 0 && selectedIds.length >= listRecordsLength;

    return {
      loading,
      hasAllSelected: hasAllAccountsSelectedInPage,
      indeterminate: numOfSelectedAccountsInPage !== listRecordsLength,
      selectedSocialMediaAccounts,
      checked: hasAtLeastOneAccountSelectedInPage,
      onClick: async () => {
        setTogglingState({ id: null, loading: true });
        const { error } = await toggleSocialMediaSelectedInfluencer({
          isChecked: hasAllAccountsSelectedInPage,
          socialType: SocialAccountType[socialMedia],
          socialAccountIds: listRecords.map((i) => Number(i.socialAccountId)) || [],
          selectedSocialMediaAccounts: listRecords.map((acc) => ({
            socialAccountType: acc.socialAccountType,
            socialAccountId: acc.socialAccountId,
            influencerId: Number(acc.id)
          }))
        });
        if (error) {
          resetLoadingState();
        }
      },
      disabled: loading || listRecords.length === 0,
      label: hasAtLeastOneAccountSelectedInPage
        ? t('numberInfluencersAddedToPackage', {
            count: numOfSelectedAccountsInPage,
            packageName: proposalSummary?.name
          })
        : t('addInfluencersToPackage', { packageName: proposalSummary?.name })
    };
  };

  const getListSelectButtonProps = (profile: Profile) => {
    const id = Number(profile.id);
    const socialAccountId = Number(profile.socialAccountId);
    const socialAccountMedia = SocialAccountType[socialMedia];
    const loading = togglingState.id === socialAccountId && togglingState.loading;
    const isSelected =
      !!socialPairsAddedToPackage?.length &&
      socialPairsAddedToPackage.findIndex(
        (pair) =>
          pair.influencerId === id && pair.socialAccountId === socialAccountId && pair.socialType === socialAccountMedia
      ) !== -1;

    return {
      loading,
      checked: isSelected,
      disabled: loading,
      onClick: async () => {
        setTogglingState({ id: socialAccountId, loading: true });
        const { error } = await toggleSocialMediaSelectedInfluencer({
          socialType: socialAccountMedia,
          isChecked: isSelected,
          selectedSocialMediaAccounts: [
            {
              socialAccountId: Number(profile.socialAccountId),
              socialAccountType: socialAccountMedia,
              influencerId: id
            }
          ],
          socialAccountIds: [socialAccountId]
        });
        if (error) {
          resetLoadingState();
        }
      }
    };
  };

  return {
    getSortSelectButtonProps,
    getListSelectButtonProps,
    getCurrentProfiles: setCurrentProfilesInPage
  };
};
