import { InfluencersTabType } from '@/shared/types';
import { XhsInfluencers, XhsInfluencerProfile } from '@/shared/organisms';
import { useSelectSocialAccountsForPackage } from './hooks';

export const XhsPackageInfluencersSearch = () => {
  const { getCurrentProfiles, getSortSelectButtonProps, getListSelectButtonProps } =
    useSelectSocialAccountsForPackage<XhsInfluencerProfile>({ socialMedia: InfluencersTabType.XHS });

  return (
    <XhsInfluencers
      isPackagePage
      getCurrentProfiles={getCurrentProfiles}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
