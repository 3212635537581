import { useState } from 'react';
import { getValuableVariables } from '@/shared/utils';
import { ParamsSearchReturnType } from '@/shared/hooks';
import { Maybe, TiktokInfluencerV2, useAllTiktokInfluencersV2Query } from '@/graphql';
import { formatInfluencerProfile } from '../utils';
import { TiktokInfluencersSearchSchemaType, TiktokInfluencersProps } from '../schemaTypes';
import { useGetInfluencersQueryVariables } from '../../hooks';

type Props = Pick<ParamsSearchReturnType<TiktokInfluencersSearchSchemaType>, 'page' | 'limit' | 'filter' | 'sort'> &
  Pick<TiktokInfluencersProps, 'getCurrentProfiles'>;

export const useTiktokInfluencersList = ({ page, sort, limit, filter, getCurrentProfiles }: Props) => {
  // Using data directly from query can be empty if skipSearch is true
  // But we still want to keep old data in that case, so we store it in state
  const [data, setData] = useState<{
    totalNumber?: number;
    tiktokAccounts?: Maybe<readonly TiktokInfluencerV2[]>;
  }>();
  const { skipSearch, variables } = useGetInfluencersQueryVariables({ page, limit, filter });
  const { loading } = useAllTiktokInfluencersV2Query({
    skip: skipSearch || !filter,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: getValuableVariables({ ...variables, orderBy: sort }),
    onCompleted: ({ allTiktokInfluencersV2 }) => {
      setData({
        tiktokAccounts: allTiktokInfluencersV2?.tiktokAccounts,
        totalNumber: allTiktokInfluencersV2?.totalNumber
      });
      getCurrentProfiles?.(allTiktokInfluencersV2?.tiktokAccounts?.map(formatInfluencerProfile) || []);
    }
  });

  return {
    loading,
    totalRecords: data?.totalNumber || 0,
    listRecords: data?.tiktokAccounts?.map(formatInfluencerProfile) || []
  };
};
