import { DouyinInfluencerProfile, DouyinInfluencers } from '@/shared/organisms';
import { InfluencersTabType } from '@/shared/types';
import { useSelectSocialAccountsForPackage } from './hooks';

export const DouyinPackageInfluencersSearch = () => {
  const { getCurrentProfiles, getSortSelectButtonProps, getListSelectButtonProps } =
    useSelectSocialAccountsForPackage<DouyinInfluencerProfile>({ socialMedia: InfluencersTabType.DOUYIN });

  return (
    <DouyinInfluencers
      isPackagePage
      getCurrentProfiles={getCurrentProfiles}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
