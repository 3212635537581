import React, { createContext, useContext } from 'react';
import { ListWithPagination } from '@/shared/molecules';
import { SocialAccountInfluencerListSortFieldV2 } from '@/graphql';
import { useInfluencerParamsSearch } from '../hooks';
import { InfluencersContextReturnType } from '../schemaTypes';
import { useXhsInfluencersList } from './hooks';
import {
  XhsInfluencersProps,
  XhsInfluencerProfile,
  XhsInfluencersFilterFormValues,
  XhsInfluencersSearchSchemaType
} from './schemaTypes';

type ContextType = InfluencersContextReturnType<
  XhsInfluencerProfile,
  XhsInfluencersFilterFormValues,
  SocialAccountInfluencerListSortFieldV2
>;

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  value: XhsInfluencersProps;
  children: React.ReactElement | Array<React.ReactElement>;
}

export const XhsInfluencersProvider = ({
  children,
  value: {
    isTtcmCampaign,
    isPackagePage,
    isEngagementPage,
    getCurrentProfiles,
    getSortSelectButtonProps,
    getListSelectButtonProps
  }
}: Props) => {
  const paramsSearch = useInfluencerParamsSearch<XhsInfluencersSearchSchemaType>();
  const { page, sort, limit, filter } = paramsSearch;
  const listProps = useXhsInfluencersList({ page, sort, limit, filter, getCurrentProfiles });

  return (
    <Context.Provider
      value={{
        ...paramsSearch,
        ...listProps,
        isPackagePage,
        isTtcmCampaign,
        isEngagementPage,
        getListSelectButtonProps,
        sortSelectButtonProps: getSortSelectButtonProps?.(listProps.listRecords, listProps.totalRecords)
      }}
    >
      <ListWithPagination pagination={{ totalRecords: listProps.totalRecords }}>{children}</ListWithPagination>
    </Context.Provider>
  );
};

export const useXhsInfluencersContext = () => useContext<ContextType>(Context);
