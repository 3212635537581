import { AllInfluencers } from '@/shared/organisms';
import { useAllInfluencersSelectForPackage } from './hooks';

export const AllPackageInfluencersSearch = () => {
  const { getCurrentProfiles, getSortSelectButtonProps, getListSelectButtonProps } =
    useAllInfluencersSelectForPackage();

  return (
    <AllInfluencers
      isPackagePage
      getCurrentProfiles={getCurrentProfiles}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
