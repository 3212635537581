import { useState } from 'react';
import { getValuableVariables } from '@/shared/utils';
import { ParamsSearchReturnType } from '@/shared/hooks';
import { useAllYoutubeInfluencersV3Query, YoutubeInfluencerSearchResultV3 } from '@/graphql';
import { formatInfluencerProfile } from '../utils';
import { YoutubeInfluencersSearchSchemaType, YoutubeInfluencersProps } from '../schemaTypes';
import { useGetInfluencersQueryVariables } from '../../hooks';

type Props = Pick<ParamsSearchReturnType<YoutubeInfluencersSearchSchemaType>, 'page' | 'limit' | 'filter' | 'sort'> &
  Pick<YoutubeInfluencersProps, 'getCurrentProfiles'>;

export const useYoutubeInfluencersList = ({ page, sort, limit, filter, getCurrentProfiles }: Props) => {
  // Using data directly from query can be empty if skipSearch is true
  // But we still want to keep old data in that case, so we store it in state
  const [data, setData] = useState<YoutubeInfluencerSearchResultV3>();
  const { skipSearch, variables } = useGetInfluencersQueryVariables({ page, limit, filter });
  const { loading } = useAllYoutubeInfluencersV3Query({
    skip: skipSearch || !filter,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: getValuableVariables({ ...variables, orderBy: sort }),
    onCompleted: ({ allYoutubeInfluencersV3 }) => {
      setData(allYoutubeInfluencersV3);
      getCurrentProfiles?.(allYoutubeInfluencersV3?.youtubeAccounts?.map(formatInfluencerProfile) || []);
    }
  });

  return {
    loading,
    totalRecords: data?.totalNumber || 0,
    listRecords: data?.youtubeAccounts?.map(formatInfluencerProfile) || []
  };
};
