import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/CheckInfluencersAddedToPackage.graphql';
import { Query, QuerycheckInfluencersAddedToPackageArgs } from '../../__generated__/globalTypes';

export const useCheckInfluencersAddedToPackageQuery = (
  options?: QueryHookOptions<Query, QuerycheckInfluencersAddedToPackageArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerycheckInfluencersAddedToPackageArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useCheckInfluencersAddedToPackageLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerycheckInfluencersAddedToPackageArgs>
): QueryResult<Query, QuerycheckInfluencersAddedToPackageArgs> & {
  getCheckInfluencersAddedToPackage: LazyQueryExecFunction<Query, QuerycheckInfluencersAddedToPackageArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getCheckInfluencersAddedToPackage, result] = useLazyQuery<Query, QuerycheckInfluencersAddedToPackageArgs>(
    QUERY,
    {
      onError: (error) => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
      ...options
    }
  );

  return { getCheckInfluencersAddedToPackage, ...result };
};

export const useCheckInfluencersAddedToPackagePromiseQuery = () => {
  const client = useApolloClient();

  const getCheckInfluencersAddedToPackage = useCallback(
    (options?: Omit<QueryOptions<QuerycheckInfluencersAddedToPackageArgs, Query>, 'query'>) =>
      client.query<Query, QuerycheckInfluencersAddedToPackageArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getCheckInfluencersAddedToPackage };
};
