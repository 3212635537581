import { ThreadsInfluencers, ThreadsInfluencerProfile } from '@/shared/organisms';
import { InfluencersTabType } from '@/shared/types';
import { useSelectSocialAccountsForPackage } from './hooks';

export const ThreadsPackageInfluencersSearch = () => {
  const { getCurrentProfiles, getSortSelectButtonProps, getListSelectButtonProps } =
    useSelectSocialAccountsForPackage<ThreadsInfluencerProfile>({ socialMedia: InfluencersTabType.THREADS });

  return (
    <ThreadsInfluencers
      isPackagePage
      getCurrentProfiles={getCurrentProfiles}
      getSortSelectButtonProps={getSortSelectButtonProps}
      getListSelectButtonProps={getListSelectButtonProps}
    />
  );
};
