import { useParams } from '@tanstack/react-router';
import {
  ConnectPackageWithInfluencerAccountsInput,
  MutationdeleteInfluencerPackageArgs,
  namedOperations,
  useConnectPackageWithInfluencerAccountsMutation,
  useDeleteInfluencerPackageMutation
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';

export const useToggleSelectedPackageInfluencers = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const params = useParams({ from: '/_private-routes/package/$id/content/search/' });
  const { callConnectPackageWithInfluencerAccounts, loading: isUpdatingPackage } =
    useConnectPackageWithInfluencerAccountsMutation({
      refetchQueries: [namedOperations.Query.PackageProposal, namedOperations.Query.CheckInfluencersAddedToPackage]
    });
  const { callDeleteInfluencerPackage, loading: isDeletingProposal } = useDeleteInfluencerPackageMutation({
    refetchQueries: [namedOperations.Query.PackageProposal, namedOperations.Query.CheckInfluencersAddedToPackage]
  });

  const toggleSelectedInfluencer = async (influencerIds: number[], isChecked: boolean) => {
    let error;
    const input: ConnectPackageWithInfluencerAccountsInput | MutationdeleteInfluencerPackageArgs = {
      influencerIds,
      packageId: Number(params.id)
    };

    try {
      if (isChecked) {
        await callDeleteInfluencerPackage({ variables: input });
      } else {
        await callConnectPackageWithInfluencerAccounts({ variables: { input } });
      }
      // we don't want to notify user on each save/delete, just show an error if happens
    } catch (err) {
      error = err.message;
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }

    return { error };
  };

  return { toggleSelectedInfluencer, loading: isUpdatingPackage || isDeletingProposal };
};
